/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useState } from "react";
import { Select, Radio, Collapse, Button, RadioChangeEvent, Input, Switch, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Content, DetailsCar } from "./styles";
// import Button from '../../components/Button'
import { listProducts } from "../../utils/listProducts";
import { setTabs } from "../../store/modules/tabsRegisterUser/actions";
import { useToast } from "../../hooks/toast";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { IState } from "../../store";
import { ILocation } from "../../store/modules/location/types";
import * as Segment from "../../utils/segment";
import ImgWithFallback from "../../components/ImgWithFallback";
import { defaultBackendUrl } from "services/api";
import noImageCar from "assets/defaultImages/no-image.png";
import "../../styles/bootstrap-grid.css";
import ClearIcon from "../../assets/icons/backspace.svg";
import PaginationList from "components/PaginationList";

const { Panel } = Collapse;

export interface Estado {
    id: number;
    sigla: string;
    nome: string;
    regiao?: Estado;
}

export interface Cidade {
    id: number;
    nome: string;
    microrregiao: Microrregiao;
    "regiao-imediata": RegiaoImediata;
}

export interface Microrregiao {
    id: number;
    nome: string;
    mesorregiao: Mesorregiao;
}

export interface Mesorregiao {
    id: number;
    nome: string;
    UF: Uf;
}

export interface Uf {
    id: number;
    sigla: string;
    nome: string;
    regiao?: Uf;
}

export interface RegiaoImediata {
    id: number;
    nome: string;
    "regiao-intermediaria": Mesorregiao;
}

interface iPropsProducts {
    color: string;
    productId: number;
    quantity: number;
    modelCode: number;
    model: string;
    optional: string;
    deadline: number;
    monthlyKmValue: number;
    monthlyInstallment: number;
    hullFranchiseValue: string;
    overrunKm: number;
    defaultValueCity: string;
    id: number;
    stateForPickup: string;
    totalRequestCredit: number;
    isDNPickupLocation: string;
    defaultOptional: string;
}

interface IProductsOrder {
    quantity: number;
    productId: number;
    cityForPickup: string;
    uf: string;
    id: number;
    optional: string;
}

interface IDealership {
    name: string;
    street: string;
    addressNumber: string;
}

export interface OrderITem {
    productId: number;
    finalPlate: any | null;
    deliveryOption: number | null;
    cityforPickup: any;
    uf: any;
    address: Address | null;
    canChosseLicensePlate: boolean;
    willChosseLicensePlate: boolean;
    licensePlate: string | null | any[] | any;
    dealer?: number | null;
    dealerInfos?: any | null;
    // dealer?: string | null;
    city?: string | null;
    locationsOptions?: any[] | any;
    ufId?:number | null;
}

export interface Address {
    zipCode?: string | null;
    state?: string | null;
    city?: string | null;
    district?: string | null;
    street?: string | null;
    number?: string | null;
    complement?: string | null;
}

const PickupLocation = () => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const { addToast } = useToast();
    const { dealershipId, userId } = useAuth();
    const history = useHistory();

    const [perPage] = useState(5);
    const [total, setTotal] = useState(0);

    const [statesBr, setStatesBr] = useState<any>([]);
    const [citiesBr, setCitiesBr] = useState([]);
    const [uf, setUf] = useState("");
    const [selectedDelivery, setSelectedDelivery] = useState(1);
    const [buttonIsVisible, setButtonIsVisible] = useState<boolean>(false);

    const [productsData, setProductsData] = useState<iPropsProducts[]>([]);
    const [allProducts, setAllProducts] = useState<iPropsProducts[]>([]);
    const [products, setProducts] = useState<IProductsOrder[]>([]);
    const [dealershipAudi, setDealershipAudi] = useState(false);
    const [dealership, setDealership] = useState<IDealership>();
    const [updateProducts, setUpdateProducts] = useState(true);
    const tabId = useSelector<IState, ILocation>((state) => state.tabs);
    const segmentId = localStorage.getItem("@FleetSolutions:segmentId");

    const [canSelectLicensePlate, setCanSelectLicensePlate] = useState<boolean>(false);

    const [states, setStates] = useState<Estado[]>([]);

    const [allOptionsValue, setAllOptionsValue] = useState<any>();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentUf, setCurrentUf] = useState<any>();
    const [currentCity, setCurrentCity] = useState<any>();
    const [currentAddress, setCurrentAddress] = React.useState<any>({
      zipCode: "",
      street: "",
      number: "",
      district: "",
      complement: "",
      city: "",
      state: "",
      canChosseLicensePlate:false
    });

    const [orderItems, setOrderItems] = React.useState<OrderITem[]>([]);
    const [orderItemsCities, setOrderItemsCities] = React.useState<any[]>([]);

    const [deliveryOptions, setDeliveryOptions] = useState<any>([] as any);

    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalRecords, setTotalRecords] = useState<any>(0);
    const [data, setData] = useState<any>();

    const [currentDealerUf, setCurrentDealerUf] = useState<any>();
    const [currentDealerCity, setCurrentDealerCity] = useState<any>();
    const [currentDealer, setCurrentDealer] = useState<any>(null);
    const [currentBrandCode, setCurrentBrandCode] = useState<any>(null);

    const [locationsOptions, setLocationsOptions] = useState([] as any);

    const [dealerStates, setDealerStates] = useState<any>([]);
    const [dealerCities, setDealerCities] = useState<any>([]);

    const allOptions = [
        { label: "DN do pedido", value: 1 },
        { label: "Indicar Estado/Cidade", value: 4 },
        { label: "Endereço do cadastro do cliente", value: 2 },
        { label: "Indicar outro endereço", value: 3 },
    ];

    const alternativaOptions = [
        { label: "DN do pedido", value: 1 },
        { label: "Indicar Estado/Cidade", value: 4 },
    ];

    useEffect(() => {
        if (!userId) {
            history.push("/");
        }

        if (Number(tabId.id) === 2) {
            api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`).then((response) => {
                setStates(response.data);
            });
            setProducts([]);
            setAllProducts([]);
            setProductsData([]);
            localStorage.removeItem("@FleetSolutions:location");
            

            setUpdateProducts(true);

            const productsLocal = localStorage.getItem("@FleetSolutions:cart");

            const orderItemsStorage = localStorage.getItem("@FleetSolutions:orderItems");
            if (orderItemsStorage) {
                setOrderItems(JSON.parse(orderItemsStorage));
            }
            if (productsLocal) {
                const products = JSON.parse(productsLocal);

                if (products[0].quantity) {
                    const newData = products.map((item: iPropsProducts) => {
                        return listProducts(item);
                    });

                    const data: any = [];

                    newData.forEach((item: any, index: number) => {
                        data.push(...newData[index]);
                    });

                    data.map((item: iPropsProducts) => {
                        item.defaultValueCity = "Selecione";
                        item.isDNPickupLocation = "false";
                    });

                    setAllProducts(data);

                    if (orderItemsStorage) {
                        setOrderItems(JSON.parse(orderItemsStorage));
                    } else {
                        let orderItemsArr: OrderITem[] = [] as OrderITem[];

                        data.forEach((element: { productId: any }) => {
                            orderItemsArr.push({
                                productId: element.productId,
                                finalPlate: null,
                                deliveryOption: null,
                                cityforPickup: null,
                                uf: null,
                                address: null,
                                canChosseLicensePlate: canSelectLicensePlate,
                                willChosseLicensePlate: false,
                                licensePlate: null,
                            });
                        });
                        setOrderItems(orderItemsArr);
                    }
                } else {
                    products.map((item: any) => {
                        item.defaultValueCity = "Selecione";
                        item.isDNPickupLocation = false;
                    });

                    setAllProducts(products);
                    if (orderItemsStorage) {
                        setOrderItems(JSON.parse(orderItemsStorage));
                    } else {
                        let orderItemsArr: OrderITem[] = [] as OrderITem[];

                        products.forEach((element: { productId: any }) => {
                            orderItemsArr.push({
                                productId: element.productId,
                                finalPlate: null,
                                deliveryOption: null,
                                cityforPickup: null,
                                uf: null,
                                address: null,
                                canChosseLicensePlate: canSelectLicensePlate,
                                willChosseLicensePlate: false,
                                licensePlate: null,
                            });
                        });
                        setOrderItems(orderItemsArr);
                    }
                }
            }
            localStorage.removeItem("@FleetSolutions:orderItems");

            const cnpjCompany = localStorage.getItem("@FleetSolutions:cnpjCompany");

            if (cnpjCompany) {
                api.get(`/company/${cnpjCompany.replace(/\D/g, "")}`).then((resCompany) => {
                    if (resCompany.data) {
                        setCanSelectLicensePlate(false);
                        api.get(`location/finalPlatePermissions/ByStateCity?state=${resCompany.data.address.state}&city=${normalizeString(resCompany.data.address.city)}`).then((resLicensePlate) => {
                            if(resLicensePlate.data){
                                setCanSelectLicensePlate(true);

                                if(orderItems?.length){
                                    const updatedOrderItems = [...orderItems];

                                    updatedOrderItems.forEach((item, index) => {
                                        updatedOrderItems[index].canChosseLicensePlate = true;
                                    });
                                    setOrderItems(updatedOrderItems);
                                }

                            }
                        }).catch(() => {
                            setCanSelectLicensePlate(false);
                            if(orderItems?.length){
                                const updatedOrderItems = [...orderItems];

                                updatedOrderItems.forEach((item, index) => {
                                    updatedOrderItems[index].canChosseLicensePlate = false;
                                });
    
    
                                setOrderItems(updatedOrderItems);
                            }
                          });
                    }
                });
            }
        }


    }, [tabId.id]);

    useEffect(() => {
        const cnpjCompany = localStorage.getItem("@FleetSolutions:cnpjCompany");

        api.get(`/dealerships/${dealershipId}`).then((res) => {
            const { data } = res;
            setDealership(data);
            const allowPFAudi = data.segments && data.segments.indexOf(Segment.audiPF + ",") > -1;
            const allowPJAudi = data.segments && data.segments.indexOf(Segment.audiPJ + ",") > -1;

            if (allowPFAudi || allowPJAudi) {
                setDealershipAudi(true);
            }

            // if (cnpjCompany) {
            //     api.get(`/company/${cnpjCompany.replace(/\D/g, "")}`).then((resCompany) => {
            //         if (resCompany.data) {
            //             api.get(`location/finalPlatePermissions/ByStateCity?state=${resCompany.data.address.state}&city=${normalizeString(resCompany.data.address.city)}`).then((resLicensePlate) => {
            //                 const { data } = resLicensePlate;
            //                 setCanSelectLicensePlate(resLicensePlate ? true : false);
            //             });
            //         }
            //     });
            // }
        });
    }, []);

    function normalizeString(str: string) {
        const specialCharacters: any = {
            Á: "A",
            À: "A",
            Ã: "A",
            Â: "A",
            É: "E",
            È: "E",
            Ê: "E",
            Í: "I",
            Ì: "I",
            Î: "I",
            Ó: "O",
            Ò: "O",
            Õ: "O",
            Ô: "O",
            Ú: "U",
            Ù: "U",
            Û: "U",
            Ç: "C",
        };

        const normalizedStr = Array.from(str)
            .map((char) => specialCharacters[char.toUpperCase()] || char.toUpperCase())
            .join("");

        return normalizedStr;
    }

    useEffect(() => {
        api.get(`location/state`).then((response: any) => {
            setStatesBr(response.data)
        })
        // const url = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
        // fetch(url)
        //     .then((res) =>
        //         res.json().then(function (data) {
        //             setStatesBr(
        //                 data.sort((a: any, b: any) => {
        //                     if (a.nome > b.nome) {
        //                         return 1;
        //                     }
        //                     if (a.nome < b.nome) {
        //                         return -1;
        //                     }

        //                     return 0;
        //                 })
        //             );
        //         })
        //     )
        //     .catch((err) => {
        //         console.log(err);
        //     });


    }, []);

    useEffect(() => {
        if (updateProducts) {
            const indexOfLastProduct = currentPage * perPage;
            const indexOfFirstProduct = indexOfLastProduct - perPage;
            const currentProducts = allProducts.slice(indexOfFirstProduct, indexOfLastProduct);

            setTotal(allProducts.length);
            setProductsData(currentProducts);
        }
    }, [currentPage, perPage, allProducts]);

    useEffect(() => {
        api.get(`/segments/by-id-fleet/${segmentId}`).then(({ data }) => {
            const firstActiveSegment = data?.find((item: { status: boolean }) => item?.status === true);

            const deliveryTypesArray = firstActiveSegment.deliveryTypes?.split("|").map(Number) || [];

            const fetchData = async () => {
                const { deliveryOptions } = await fetchDeliveryOptions();

                setDeliveryOptions(
                    deliveryOptions
                        .map((item: { description: any; id: any }) => ({
                            label: item.description,
                            value: item.id,
                        }))
                        .filter((item: { value: any }) => {
                            return deliveryTypesArray.includes(Number(item.value));
                        })
                );
            };

            fetchData();
        });
    }, [segmentId]);

    const isProductChecked = (id: number) => !!products.find((productsItem) => productsItem.id === id && productsItem.cityForPickup === "");

    const fetchDeliveryOptions = async () => {
        try {
            const [deliveryResponse] = await Promise.all([api.get("/enumparameters/DeliveryOptionEnum")]);
            return {
                deliveryOptions: deliveryResponse.data,
            };
        } catch (error) {
            return { deliveryOptions: [] };
        }
    };

    const errorMessage = () => {
        return addToast({
            title: "Selecione o local de retirada",
            description: "É necessário selecionar o local de retirada para todos os veículos.",
            type: "error",
        });
    }

    const handleContinueOrder = async () => {

        const isValid = orderItems.every((item) => {
            if (item.deliveryOption === null) {
                errorMessage();
                return false;
            }

            if (item.deliveryOption === 5 && (item.cityforPickup === null || item.uf === null)) {
                errorMessage();
                return false;
            }

            if (item.deliveryOption === 3 && (item.address === null || Object.values(item.address).some((value) => value === null))) {
                errorMessage();
                return false;
            }

            if (item.canChosseLicensePlate && item.willChosseLicensePlate && (item.licensePlate === null || item.licensePlate.length < 2)){
                addToast({
                    title: "Final de placa",
                    description: "É necessário selecionar ao menos duas opções de final de placa.",
                    type: "error",
                });
                return false;
            }

            return true;
        });


        if (!isValid) {
            return false;
        }
        
        const productsLocal = localStorage.getItem("@FleetSolutions:cart");
        const locationList: any = [];

        if (productsLocal) {
            const originalProducts = JSON.parse(productsLocal);
            allProducts.forEach((product: any, index: number) => {
                const newProductOrder = {
                    productId: product.productId,
                    uf: product.stateForPickup,
                    cityForPickup: product.defaultValueCity === "Selecione" ? "" : product.defaultValueCity,
                    quantity: 1,
                    id: product.id,
                    optional: product.optional,
                    modelYear: product.modelYear,
                };

                locationList.push(newProductOrder);

                product.quantity = 1;
                product.modelYear = originalProducts.find((item: { modelCode: any }) => item.modelCode === product.modelCode)?.modelYear;
                product.model = originalProducts.find((item: { modelCode: any }) => item.modelCode === product.modelCode)?.model;
                product.brandCode = originalProducts.find((item: { modelCode: any }) => item.modelCode === product.modelCode)?.brandCode;
                
            });
        }

        localStorage.setItem("@FleetSolutions:location", JSON.stringify(locationList));
        localStorage.setItem("@FleetSolutions:selectedDelivery", String(selectedDelivery));
        localStorage.setItem("@FleetSolutions:cart", JSON.stringify(allProducts));

        localStorage.setItem("@FleetSolutions:orderItems", JSON.stringify(orderItems));

        dispatch(setTabs({ id: "3" }));
        return;

        const selectedCity = allProducts.filter((item) => item.defaultValueCity !== "Selecione");

        // const isSelectDN = allProducts.filter((item) => {
        //     return item.isDNPickupLocation.toString() === "true";
        // });

        // if (allProducts.length !== selectedCity.length + isSelectDN.length) {
        //     return addToast({
        //         title: "Selecione o local de retirada",
        //         description: "É necessário selecionar o local de retirada para todos os veículos.",
        //         type: "error",
        //     });
        // }

        localStorage.setItem("@FleetSolutions:location", JSON.stringify(products));
        localStorage.setItem("@FleetSolutions:cart", JSON.stringify(allProducts));
        localStorage.setItem("@FleetSolutions:orderItems", JSON.stringify(orderItems));

        dispatch(setTabs({ id: "3" }));
    };

    const handleChangeDealership = (value: string, id: number) => {
        const product = productsData.find((item) => item.id === id);
        const productOfAll = allProducts.filter((item) => item.id === id)[0];

        productOfAll.stateForPickup = value;

        if (product) {
            product.defaultValueCity = "Selecione";
        }

        setUf(value);
        let ufId = statesBr?.find((item: { text: string; }) => item.text === value)?.value
        api.get(`location/city?uf=${ufId}`).then((response: any) => {
            setCitiesBr(response.data)
        })
        // const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`;
        // fetch(url)
        //     .then((res) =>
        //         res.json().then(function (data) {
        //             setCitiesBr(data);
        //         })
        //     )
        //     .catch((err) => {
        //         console.log(err);
        //     });
    };

    const getAccessories = (value: any) => {
        let accessories = "";

        if (!value) return;

        value.split("|").map((ite: string) => {
            if (ite.indexOf("acs") > -1) {
                accessories += ite.replace("acs=", "") + "; ";
            }
        });

        return accessories;
    };

    const getOptionals = (value: any) => {
        let optionals = "";

        if (!value) return;

        value.split("|").map((ite: string) => {
            if (ite.indexOf("opt") > -1) {
                optionals += ite.replace("opt=", "") + "; ";
            }
        });

        return optionals;
    };

    const handleChangeCities = (ci: string, product: iPropsProducts) => {
        const productFind = productsData.filter((item) => item.id === product.id)[0];
        const productOfAll = allProducts.filter((item) => item.id === product.id)[0];

        productFind.defaultValueCity = ci;
        productOfAll.defaultValueCity = ci;

        const newProductOrder = {
            productId: product.productId,
            uf: uf,
            cityForPickup: ci,
            quantity: 1,
            id: productOfAll.id,
            optional: product.defaultOptional,
        };

        setProducts([...products, newProductOrder]);
    };

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleChangeDN = (event: any, id: number) => {
        const dnChecked = event.target.checked;
        const product = allProducts.find((item) => item.id === id);
        const removedProduct = allProducts.filter((item) => item.id !== id);

        if (product) {
            product.isDNPickupLocation = dnChecked;
            product.defaultValueCity = "Selecione";

            if (dnChecked) {
                const newProductOrder = {
                    productId: product.productId,
                    uf: "",
                    cityForPickup: "",
                    quantity: 1,
                    id: id,
                    optional: product.defaultOptional,
                };

                setProducts([...products, newProductOrder]);
                setAllProducts([...removedProduct, product]);
            } else {
                setProducts(products.filter((item) => item.id !== id));
                setAllProducts([product, ...removedProduct]);
            }
        }
    };

    const handleBackPage = () => {
        dispatch(setTabs({ id: "1" }));
    };

    const visibleSegmentDeliveryPlace = () => {
        return true;
    };

    const visibleDeliveryPlace = () => {
        return selectedDelivery === 1;
    };

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const onChangeOption = (event: any, index: number) => {
        const updatedOrderItems = [...orderItems];

        updatedOrderItems[index].deliveryOption = event.target.value;

            updatedOrderItems[index].address = null;
            updatedOrderItems[index].canChosseLicensePlate = canSelectLicensePlate;
            updatedOrderItems[index].cityforPickup = null;
            updatedOrderItems[index].uf = null;
            updatedOrderItems[index].city = null;
            updatedOrderItems[index].locationsOptions = [];
            updatedOrderItems[index].dealer = null;

        setOrderItems(updatedOrderItems);

        const productsLocal = localStorage.getItem("@FleetSolutions:cart");

        if (productsLocal) {
            const products = JSON.parse(productsLocal);

            let brandCode = products.filter((product: { productId: number; }) => product.productId === allProducts[index]?.productId)[0]?.brandCode;

            setCurrentBrandCode(brandCode)


            api.get(`location/state?brandCode=${brandCode}`).then((response: any) => {
                setDealerStates(response.data);
            });
        }

    };

    const applyAllSelection = (event: any) => {
        setOrderItems((prevOrderItems) => {
            const updatedOrderItems = [...prevOrderItems];

            updatedOrderItems.forEach((element) => {
                element.deliveryOption = event;
            });

            return updatedOrderItems;
        });
    };

    const CustomPanel = ({ header, onClear }: any) => {
        const handleClearClick = (e: { stopPropagation: () => void }) => {
            e.stopPropagation();
            onClear();
        };
        return (
            <div className="custom-header">
                <span>{header}</span>
                <div className="action">
                    <Button type="text" onClick={handleClearClick} className="clear-button">
                        <img src={ClearIcon} alt="Limpar" />
                        Limpar
                    </Button>
                </div>
            </div>
        );
    };

    const handleAllSelection = (value:any) => {
      setCurrentUf(null)
      setCurrentCity(null)
      setCurrentAddress({
        zipCode: "",
        street: "",
        number: "",
        district: "",
        complement: "",
        city: "",
        state: "",
        canChosseLicensePlate:false
    })
      setAllOptionsValue(value)
      if(value === 1){
        api.get(`location/state`).then((response: any) => {
            setDealerStates(response.data);
          setIsModalOpen(value === 5 || value === 3 || value === 1 ? true : false);

        });
      }
    }

    const handleOk = () => {
      setIsModalOpen(false)
      setOrderItems((prevOrderItems) => {
        const updatedOrderItems = [...prevOrderItems];

        updatedOrderItems.forEach(element => {
          if(allOptionsValue === 5){
            element.uf = currentUf
            element.cityforPickup = currentCity
          }
          else if(allOptionsValue === 3){
            element.address = currentAddress
            element.canChosseLicensePlate = currentAddress.canChosseLicensePlate
          }
          element.deliveryOption = allOptionsValue

        });

        return updatedOrderItems;
      });
      setCurrentAddress({
        zipCode: "",
        street: "",
        number: "",
        district: "",
        complement: "",
        city: "",
        state: "",
        canChosseLicensePlate:false
      })
    }

    const handleCancel = () => {
      setIsModalOpen(false)
      
    }


    const handleModalForm = () => {
        if(allOptionsValue === 1){
            return renderDealershipForm(0, true)
        }
        if(allOptionsValue === 3){
            return renderAddressForm(0, true)
        }
        if(allOptionsValue === 5){
            return renderUfAndCityForm(0, true)
        }
    }

    const handleProductList = () => {
        const handleClear = (index: number) => {
            setOrderItems((prevOrderItems) => {
                const updatedOrderItems = [...prevOrderItems];

                updatedOrderItems[index].deliveryOption = null;
                updatedOrderItems[index].uf = null;
                updatedOrderItems[index].cityforPickup = null;
                updatedOrderItems[index].address = null;
                updatedOrderItems[index].licensePlate = null;
                updatedOrderItems[index].canChosseLicensePlate = canSelectLicensePlate;
                updatedOrderItems[index].willChosseLicensePlate = false;

                updatedOrderItems[index].city = null;
                updatedOrderItems[index].locationsOptions = [];
                updatedOrderItems[index].dealer = null;

                return updatedOrderItems;
            });
        };
        return (
            <>
                <div className="container-fluid page-head">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <h1>Local de Retirada</h1>
                            <span>Selecione a forma de retirada do(s) modelos(s)</span>
                        </div>
                    </div>
                </div>
                <div className="container all-selection">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <div className="all-selection-group">
                                <label htmlFor="zipCode">Marcar todos como: </label>
                                <Select placeholder="Selecione uma opção" value={allOptionsValue} style={{ width: 280 }} onChange={(value: any) => handleAllSelection(value)}>
                                    {!visibleSegmentDeliveryPlace()
                                        ? deliveryOptions?.map((option: any) => (
                                              <Option key={option.value} value={option.value}>
                                                  {option.label}
                                              </Option>
                                          ))
                                        : deliveryOptions?.map((option: any) => (
                                              <Option key={option.value} value={option.value}>
                                                  {option.label}
                                              </Option>
                                          ))}
                                </Select>
                                <Button className="btn-continue" onClick={() => applyAllSelection(allOptionsValue)}>
                                    Aplicar
                                </Button>
                            </div>
                            <Modal className="modal-ci" width={940} title={`Marcar todos como: ${allOptionsValue === 5 ? 'Indicar Estado/Cidade' : 'Indicar outro endereço'}`} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                              {handleModalForm()}
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">
                            <Collapse defaultActiveKey={["0"]} onChange={onChange}>
                                {allProducts.length !== 0 &&
                                    allProducts.map((item: any, index: any) => (
                                        <Panel header={<CustomPanel header={item.name} onClear={(e: any) => handleClear(index)} />} key={index}>
                                            <div className="car-infos">
                                                <div className="car-image">
                                                    <ImgWithFallback className="image-car" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.png`, `${noImageCar}`]} alt="Carro" />
                                                </div>
                                                <div className="infos">
                                                    <div className="items">
                                                        <div className="item">
                                                            <span>Cor:</span>
                                                            {item.color}
                                                        </div>
                                                        <span className="separator">|</span>
                                                        <div className="item">
                                                            <span>Prazo:</span>
                                                            {item.deadline} meses
                                                        </div>
                                                        <span className="separator">|</span>

                                                        <div className="item">
                                                            <span>Km Mensal:</span>
                                                            {item.monthlyKmValue} kms
                                                        </div>
                                                        <span className="separator">|</span>

                                                        <div className="item">
                                                            <span>Quantidade:</span>
                                                            1
                                                        </div>
                                                    </div>
                                                    <div className="items single">
                                                        <div className="item">
                                                            <span>Opcionais:</span>
                                                            {getOptionals(item.optional)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="delivery-selection">
                                            <div className="title">Selecione um local para retirada ou entrega:</div>
                                                <div className="options">
                                                    <Radio.Group options={deliveryOptions} onChange={(value) => onChangeOption(value, index)} value={orderItems[index]?.deliveryOption} optionType="button" />
                                                </div>
                                                {orderItems[index].deliveryOption === 1 && <div className="selected-option">{renderDealershipForm(index)}</div>}
                                                {orderItems[index].deliveryOption === 3 && <div className="selected-option">{renderAddressForm(index)}</div>}

                                                {orderItems[index].deliveryOption === 5 && <div className="selected-option">{renderUfAndCityForm(index)}</div>}

                                                { orderItems[index].canChosseLicensePlate && <div className="license-plate-option">{renderLicensePlate(index)}</div>}
                                            </div>
                                        </Panel>
                                    ))}
                            </Collapse>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderAddressForm = (index: number, allItems:boolean = false) => {
        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;

            if(allItems){
              setCurrentAddress((prevAddress: any) => ({
                ...prevAddress,
                [name]: value || null,
              }));

            }
            else{
              setOrderItems((prevOrderItems) => {
                const updatedOrderItems = [...prevOrderItems];
                const updatedAddress: Address = { ...updatedOrderItems[index].address };

                updatedAddress[name as keyof Address] = value || null;
                updatedOrderItems[index].address = updatedAddress;

                return updatedOrderItems;
              });
            }
        };

        const handleStateChange = (value: string, index: number) => {
            

          if(allItems){
            setCurrentAddress((prevAddress: any) => ({
              ...prevAddress,
              state: value || null,
              city: null,
            }));
          }
          else{
            setOrderItems((prevOrderItems) => {
              const updatedOrderItems = [...prevOrderItems];
              const updatedAddress: Address = { ...updatedOrderItems[index].address };

              updatedAddress["state"] = value || null;
              updatedAddress["city"] = null;
              updatedOrderItems[index].address = updatedAddress;

              return updatedOrderItems;
            });
          }
          api.get(`location/city?uf=${value}`).then((response: any) => {
            orderItemsCities[index] = response.data;

            setOrderItemsCities((prevOrderItems) => {
                const updatedOrderItems = [...prevOrderItems];
                orderItemsCities[index] = response.data;
                updatedOrderItems[index].canChosseLicensePlate = canSelectLicensePlate;

                return updatedOrderItems;
            });
            })

            // api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`).then((response) => {
            //     orderItemsCities[index] = response.data;

            //     setOrderItemsCities((prevOrderItems) => {
            //         const updatedOrderItems = [...prevOrderItems];
            //         orderItemsCities[index] = response.data;
            //         updatedOrderItems[index].canChosseLicensePlate = canSelectLicensePlate;

            //         return updatedOrderItems;
            //     });
            // });
        };

        const handleCityChange = (value: string, index: number) => {
            api.get(`location/finalPlatePermissions/ByStateCity?state=${orderItems[index].address?.state}&city=${normalizeString(value)}`).then((resLicensePlate) => {
                const { data } = resLicensePlate;
                if(allItems){
                  setCurrentAddress((prevAddress: any) => ({
                    ...prevAddress,
                    city: value || null,
                    canChosseLicensePlate: resLicensePlate ? true : false
                  }));
      

                }
                else{
                  setOrderItems((prevOrderItems) => {
                    const updatedOrderItems = [...prevOrderItems];
                    const updatedAddress: Address = { ...updatedOrderItems[index].address };

                    updatedAddress["city"] = value || null;
                    updatedOrderItems[index].address = updatedAddress;
                    updatedOrderItems[index].canChosseLicensePlate = resLicensePlate ? true : false;

                    return updatedOrderItems;
                });
                }

            });
        };

        const handleKeyUpCep = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const value = (e.target as HTMLInputElement).value;
            let previousResponses:any = [];
            if (value.length === 8) {
                api.get(`/ceps?cep=${value}`)
                    .then((response: any) => {
                        if (!response.data.erro) {
                            previousResponses.push(response.data);
                            api.get(`location/finalPlatePermissions/ByStateCity?state=${response.data.uf}&city=${normalizeString(response.data.localidade)}`).then((resLicensePlate) => {
                                const { data } = resLicensePlate;
                                previousResponses.push(data);
                            }).finally(() => {
                                
                                if(allItems){
                                    setCurrentAddress((prevAddress: any) => ({
                                      ...prevAddress,
                                      street: previousResponses[0].logradouro,
                                      number: "",
                                      district: previousResponses[0].bairro,
                                      complement: "",
                                      city: previousResponses[0].localidade,
                                      state: statesBr?.find((item: { text: any; }) => item.text === previousResponses[0].estado)?.value,
                                      canChosseLicensePlate: previousResponses[1] ? true : false
                                    }));
                                  }
                                  else{
                                    setOrderItems((prevOrderItems) => {
                                      const updatedOrderItems = [...prevOrderItems];
                                      const updatedAddress: Address = {
                                          ...updatedOrderItems[index].address,
                                      };
        
                                      updatedAddress.street = previousResponses[0].logradouro;
                                      updatedAddress.number = "";
                                      updatedAddress.district = previousResponses[0].bairro;
                                      updatedAddress.complement = "";
                                      updatedAddress.city = previousResponses[0].localidade;

                                      
                                      updatedAddress.state = statesBr?.find((item: { text: any; }) => item.text === previousResponses[0].estado)?.value;
                                      updatedOrderItems[index].canChosseLicensePlate = previousResponses[1] ? true : false;
        
                                      updatedOrderItems[index].address = updatedAddress;
        
                                      return updatedOrderItems;
                                    });
                                  }
                            });
                        }
                    })
                    .catch((error: any) => {
                        addToast({
                            title: "Campo obrigatório",
                            type: "error",
                            description: "Endereço não encontrado",
                        });
                    });
            }
        };

        return (
            <form className="form">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-3">
                            <label htmlFor="zipCode">CEP</label>
                            <Input placeholder="Digite o CEP" id="zipcode" name="zipCode" type="text" maxLength={9} value={allItems ? currentAddress['zipCode'] || '' : orderItems[index].address?.zipCode || ""} onChange={handleInputChange} onKeyUp={handleKeyUpCep} />
                        </div>
                        <div className="col-6">
                            <label htmlFor="street">Endereço</label>
                            <Input placeholder="Digite o Endereço" id="street" name="street" type="text" maxLength={80} value={allItems ? currentAddress['street'] || '' : orderItems[index].address?.street || ""} onChange={handleInputChange} />
                        </div>
                        <div className="col-3">
                            <label htmlFor="zipCode">Número</label>
                            <Input placeholder="Insira o número" id="number" name="number" type="number" maxLength={5} value={allItems ? currentAddress['number'] || '' : orderItems[index].address?.number || ""} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <label htmlFor="zipCode">Complemento</label>
                            <Input placeholder="Digite o complemento" id="complement" name="complement" type="text" maxLength={40} value={allItems ? currentAddress['complement'] || '' : orderItems[index].address?.complement || ""} onChange={handleInputChange} />
                        </div>
                        <div className="col-3">
                            <label htmlFor="zipCode">Bairro</label>
                            <Input placeholder="Digite o nome do Bairro" id="district" name="district" type="text" value={allItems ? currentAddress['district'] || '' : orderItems[index].address?.district || ""} onChange={handleInputChange} />
                        </div>

                        <div className="col-3">
                            <label htmlFor="zipCode">Estado</label>
                            <Select value={allItems ? currentAddress['state'] || '' : orderItems[index].address?.state || ""} onChange={(value: any) => handleStateChange(value, index)} placeholder="Selecione o Estado">
                                {statesBr.map((option: any) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.text}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="col-3">
                            <label htmlFor="zipCode">Cidade</label>
                            <Select value={allItems ? currentAddress['city'] || '' : orderItems[index].address?.city || ""} onChange={(value: any) => handleCityChange(value, index)} placeholder="Selecione a Cidade">
                                {orderItemsCities[index]?.map((option: any) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
            </form>
        );
    };



const renderDealershipForm = (index: number, allItems: boolean = false) => {
    const handleStateChange = (value: string) => {

        setOrderItems((prevOrderItems) => {
            const updatedOrderItems = [...prevOrderItems];
            
            if (allItems) {
                updatedOrderItems.forEach(item => {
                    let ufId = statesBr?.find((item: { value: any }) => item.value === value)?.value;
                    item.ufId = ufId;
                    item.uf = value;
                    item.city = null;
                    item.dealer = null;
                    item.locationsOptions = []; // Limpar a lista de locais ao mudar estado
                });
            } else {
                updatedOrderItems[index].uf = value;
                let ufId = statesBr?.find((item: { value: any }) => item.value === value)?.value;
                updatedOrderItems[index].ufId = ufId;
                updatedOrderItems[index].city = null;
                updatedOrderItems[index].dealer = null;
                updatedOrderItems[index].locationsOptions = []; // Limpar a lista de locais ao mudar estado
            }
            return updatedOrderItems;
        });

        setTotalRecords(0);

        if(isModalOpen){
            api.get(`location/city?uf=${value}`).then((response: any) => {
                setDealerCities(response.data);
            });
        }
        else{
            api.get(`location/city?uf=${value}&brandCode=${currentBrandCode}`).then((response: any) => {
                setDealerCities(response.data);
            });
        }


    };

    const handleCityChange = (value: string) => {
        setOrderItems((prevOrderItems) => {
            const updatedOrderItems = [...prevOrderItems];
            if (allItems) {
                updatedOrderItems.forEach(item => {
                    item.city = value;
                });
            } else {
                updatedOrderItems[index].city = value;
            }
            return updatedOrderItems;
        });

        getDealers(orderItems[index].uf, value, 1);
    };

    const selectDealer = (value: number, item?:any) => {
        setOrderItems((prevOrderItems) => {
            const updatedOrderItems = [...prevOrderItems];
            if (allItems) {
                updatedOrderItems.forEach(item => {
                    item.dealer = value;
                    item.dealerInfos = item;
                });
            } else {
                updatedOrderItems[index].dealer = value;
                updatedOrderItems[index].dealerInfos = item;
            }
            return updatedOrderItems;
        });
    };

    const paginate = (page: number) => {
        setCurrentPage(page);
        getDealers(orderItems[index].uf, orderItems[index].city!, page);
    };

    const getDealers = (uf: string, city: string, page: number) => {
        let requestUrl:string = '';
        if(isModalOpen){
            requestUrl = `location?UF=${uf}&city=${city}&page=true&quantityPerPage=50&currentPage=${page}`;
        }
        else{
            requestUrl = `location?UF=${uf}&city=${city}&page=true&quantityPerPage=50&currentPage=${page}&brandCode=${currentBrandCode}`;
        }
        api.get(requestUrl).then(({ data }: any) => {
            if (data.itens[0]) {
                setOrderItems((prevOrderItems) => {
                    const updatedOrderItems = [...prevOrderItems];
                    if (allItems) {
                        updatedOrderItems.forEach(item => {
                            item.locationsOptions = data.itens; // Atualizar a lista de locais para todos os itens
                        });
                    } else {
                        updatedOrderItems[index].locationsOptions = data.itens; // Atualizar a lista de locais para o item específico
                    }
                    return updatedOrderItems;
                });
                setData(data);
                setTotalRecords(data.totalAmount);
            } else {
                setTotalRecords(0);
                addToast({
                    title: "Sem dados",
                    type: "error",
                    description: "Não foi encontrado nenhum dado para a pesquisa.",
                });
            }
        });
    };

    return (
        <form className="form">
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <label htmlFor="state">Estado</label>
                        <Select 
                            value={orderItems[index].uf} 
                            onChange={(value: any) => handleStateChange(value)} 
                            placeholder="Selecione o Estado"
                        >
                            {dealerStates.map((option: any) => (
                                <Option key={option.value} value={option.value}>
                                    {option.text}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="col-6">
                        <label htmlFor="city">Cidade</label>
                        <Select 
                            value={orderItems[index].city || ''} 
                            onChange={(value: any) => handleCityChange(value)} 
                            placeholder="Selecione a Cidade"
                        >
                            {dealerCities?.map((option: any) => (
                                <Option key={option} value={option}>
                                    {option}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {orderItems?.[index]?.locationsOptions?.length > 0 ? (
                            <div className="locations-title mt-4 mb-3">Locais disponíveis</div>
                        ) : null}
                        {orderItems?.[index]?.locationsOptions?.map((item: any) => (
                            <div 
                                className={orderItems?.[index]?.dealer === item.id ? "location-item active" : "location-item"} 
                                key={item.id} 
                                onClick={() => selectDealer(item.id, item)}
                            >
                                <div className="name">{item.description}</div>
                                <div className="address">
                                    {item.address}, {item.number}
                                    <br />
                                    {item.district} - {item.city}, {item.uf} - CEP: {item.zipCode}
                                </div>
                            </div>
                        ))}
                        <div className="pagination">
                            <PaginationList 
                                paginate={(page: number) => paginate(page)} 
                                currentPage={currentPage} 
                                total={totalRecords} 
                                perPage={50} 
                                hideOnSinglePage={true} 
                            />
                        </div>
                    </div>
                </div>

            </div>
        </form>
    );
};


    
    
    

    const renderUfAndCityForm = (index: number, allItems: boolean = false) => {
        const handleStateChange = (value: string, index: number) => {
            let stateUfInfos = states.find((item) => item.sigla === value)?.nome;
            let ufId = statesBr?.find((item: { text: string }) => item.text === stateUfInfos)?.value;
            if (allItems) {
                setCurrentUf(value);
            } else {
                setOrderItems((prevOrderItems) => {
                    const updatedOrderItems = [...prevOrderItems];
    
                    updatedOrderItems[index].cityforPickup = null;
                    updatedOrderItems[index].uf = value;
                    updatedOrderItems[index].ufId = ufId;
    
                    return updatedOrderItems;
                });
            }


            api.get(`location/city?uf=${ufId}`).then((response: any) => {
                orderItemsCities[index] = response.data;
    
                setOrderItemsCities((prevOrderItems) => {
                    const updatedOrderItems = [...prevOrderItems];
                    orderItemsCities[index] = response.data;
    
                    return updatedOrderItems;
                });
            });
        };
    
        const handleCityChange = (value: string, index: number) => {
            let ufName = states?.find((item) => item.sigla === (allItems ? currentUf : orderItems[index]?.uf))?.nome;
            let ufId = statesBr?.find((item: { text: string }) => item.text === ufName)?.value;
    
            api.get(`location/finalPlatePermissions/ByStateCity?state=${allItems ? currentUf : orderItems[index]?.uf}&city=${normalizeString(value)}`)
                .then((resLicensePlate) => {
                    const { data } = resLicensePlate;
                    if (allItems) {
                        setCurrentCity(value[0]);
    
                        let allOrders = orderItems;
    
                        allOrders?.forEach((element) => {
                            element.canChosseLicensePlate = resLicensePlate ? true : false;
                        });
    
                        setOrderItems(allOrders);
                    } else {
                        setOrderItems((prevOrderItems) => {
                            const updatedOrderItems = [...prevOrderItems];
    
                            updatedOrderItems[index].cityforPickup = value[0];
                            updatedOrderItems[index].canChosseLicensePlate = resLicensePlate ? true : false;
    
                            return updatedOrderItems;
                        });
                    }
                })
                .catch(() => {
                    if (allItems) {
                        setCurrentCity(value[0]);
                        let allOrders = orderItems;
    
                        allOrders?.forEach((element) => {
                            element.canChosseLicensePlate = false;
                        });
    
                        setOrderItems(allOrders)
                    } else {
                        setOrderItems((prevOrderItems) => {
                            const updatedOrderItems = [...prevOrderItems];
    
                            updatedOrderItems[index].cityforPickup = value[0];
                            updatedOrderItems[index].canChosseLicensePlate = false;
    
                            return updatedOrderItems;
                        });
                    }
                });
        };
    
        return (
            <form className="form">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="zipCode">Estado</label>
                            <Select value={allItems ? currentUf : orderItems[index]?.uf || ""} onChange={(value: any) => handleStateChange(value, index)} placeholder="Selecione o Estado">
                                {states.map((option: any) => (
                                    <Option key={option.sigla} value={option.sigla}>
                                        {option.sigla}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="zipCode">Cidade</label>
                            <Select value={allItems ? currentCity : orderItems[index]?.cityforPickup || ""} onChange={(value: any) => handleCityChange(value, index)} placeholder="Selecione a Cidade">
                                {orderItemsCities[index]?.map((option: any) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
            </form>
        );
    };
    

    const renderLicensePlate = (index: number) => {
        const handleStateChange = (value: string, index: number) => {
            setOrderItems((prevOrderItems) => {
                const updatedOrderItems = [...prevOrderItems];

                updatedOrderItems[index].licensePlate = value;

                return updatedOrderItems;
            });

        };

        const onChange = (checked: boolean, index: number) => {
            setOrderItems((prevOrderItems) => {
                const updatedOrderItems = [...prevOrderItems];

                updatedOrderItems[index].willChosseLicensePlate = checked;

                return updatedOrderItems;
            });
        };

        const licensePlateOptions = () => {
            return ["1 ou 2", "3 ou 4", "5 ou 6", "7 ou 8", "9 ou 0"];
        };
        return (
            <form>
                <div className="container p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                Escolher Final de Placa
                                <span>Você selecionou uma localização em que permite escolher o final da placa. Deseja escolher?</span>
                            </div>
                        </div>
                        <div className="col-12">
                            <Switch checked={orderItems[index].willChosseLicensePlate} onChange={(value) => onChange(value, index)} />
                        </div>
                        <div className="col-12 d-flex align-items-center gap-row">
                            {orderItems[index].willChosseLicensePlate && (
                                <>
                                    <label htmlFor="zipCode">Escolha no mínimo duas opções:</label>
                                    <Select onChange={(value: any) => handleStateChange(value, index)} placeholder="Selecione os finais de placa" mode="multiple">
                                        {licensePlateOptions().map((option: any) => (
                                            <Option key={option} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </>
                            )}
                        </div>
                        {orderItems[index].willChosseLicensePlate && (
                            <div className="col-12">
                                <div className="obs d-flex justify-content-end mt-2">*Sujeito à disponibilidade de estoque, caso não haja o final de placa escolhido, entraremos em contato.</div>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        );
    };

    return (
        <Container>
            {handleProductList()}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="buttons">
                            <div className="wrapper-btn">
                                <Button className="btn-back" onClick={handleBackPage}>
                                    Voltar
                                </Button>
                                <Button className="btn-continue" onClick={handleContinueOrder}>
                                    Continuar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default PickupLocation;
