/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { Container, Content, ContentModal, Table } from './styles'
import Button from '../../components/Button'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import { setTabs } from '../../store/modules/tabsRegisterUser/actions'
import { useCallback } from 'react'
import UploadDoc from '../../components/UploadDoc'
import { Modal } from 'antd'
import { useAuth } from 'hooks/auth'

interface IProductsOrder {
  quantity: number
  productId: number
  cityForPickup: string
  uf: string
}

const UploadFile = () => {
  const { vendedorMaster } = useAuth()
  const history = useHistory()
  const { isCompany, orderId } = useParams<any>()
  const dispatch = useDispatch()
  const { addToast } = useToast()

  const [location, setLocation] = useState<IProductsOrder[]>([])
  const [companyType, setCompanyType] = useState(0)
  const [company, setCompany] = useState(null)

  const [disabledOrder, setDisabledOrder] = useState(false)
  const [showProcuration, setShowProcuration] = useState(false)

  const [downloadDocCcmei, setDownloadDocCcmei] = useState('')
  const [
    downloadDocSimplesStatementLastYear,
    setDownloadDocSimplesStatementLastYear
  ] = useState('')
  const [
    downloadDocLastYearSocialIncomeTax,
    setDownloadDocLastYearSocialIncomeTax
  ] = useState('')
  const [downloadDocLastContractChange, setDownloadDocLastContractChange] =
    useState('')
  const [downloadDocIrpjLastYear, setDownloadDocIrpjLastYear] = useState('')
  const [downloadDocLastYearRevenue, setDownloadDocLastYearRevenue] =
    useState('')
  const [
    downloadDocBalanceSheetPreviousYear,
    setDownloadDocBalanceSheetPreviousYear
  ] = useState('')
  const [
    downloadDocCurrentYearBalanceSheet,
    setDownloadDocCurrentYearBalanceSheet
  ] = useState('')
  const [downloadDocDrePreviousYear, setDownloadDocDrePreviousYear] =
    useState('')
  const [downloadDocDreCurrentYear, setDownloadDocDreCurrentYear] = useState('')
  const [downloadDocBankIndebtedness, setDownloadDocBankIndebtedness] =
    useState('')

  const [downloadDocProcuration, setDownloadDocProcuration] =
    useState('')
  const [downloadDocOthers, setDownloadDocOthers] = useState('')

  const [stockModal, setStockModal] = useState(false)
  const [outOfStockProducts, setOutOfStockProducts] = useState([])

  const userIdCompany = Number(
    localStorage.getItem('@FleetSolutions:userIdCompany')
  )

  useEffect(() => {
    searchCompany()
    const locationLocal = localStorage.getItem('@FleetSolutions:location')

    if (locationLocal) {
      const locationStorage = JSON.parse(locationLocal)

      setLocation(locationStorage)
    }
  }, [showProcuration])

  const searchCompany = async () => {
    const cnpjCompany = localStorage.getItem('@FleetSolutions:cnpjCompany')

    if (cnpjCompany) {
      const cnpjRemovedMask = cnpjCompany.replace(/\D/g, '')

      await api.get(`serasa/${cnpjRemovedMask}`).then((res: any) => {
        setCompanyType(res.data.companyType)
        setCompany(res.data)
        
        if(res.data && res.data.contacts.length > 0){
          setShowProcuration(true)
        }

      })
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    getFiles()
  }, [])

  const getFiles = () => {
    
    api.get(`/document-company-upload/${userIdCompany}`).then((res: any) => {
      const {
        ccmei,
        simplesStatementLastYear,
        lastYearSocialIncomeTax,
        lastContractChange,
        irpjLastYear,
        lastYearRevenue,
        balanceSheetPreviousYear,
        currentYearBalanceSheet,
        drePreviousYear,
        dreCurrentYear,
        bankIndebtedness,
        procuration,
        others
      } = res.data

      if (isCompany) return

      if (ccmei !== null) {
        setDownloadDocCcmei(ccmei)
      }

      if (simplesStatementLastYear !== null) {
        setDownloadDocSimplesStatementLastYear(simplesStatementLastYear)
      }

      if (lastYearSocialIncomeTax !== null) {
        setDownloadDocLastYearSocialIncomeTax(lastYearSocialIncomeTax)
      }

      if (lastContractChange !== null) {
        setDownloadDocLastContractChange(lastContractChange)
      }

      if (irpjLastYear !== null) {
        setDownloadDocIrpjLastYear(irpjLastYear)
      }

      if (lastYearRevenue !== null) {
        setDownloadDocLastYearRevenue(lastYearRevenue)
      }

      if (balanceSheetPreviousYear !== null) {
        setDownloadDocBalanceSheetPreviousYear(balanceSheetPreviousYear)
      }

      if (currentYearBalanceSheet !== null) {
        setDownloadDocCurrentYearBalanceSheet(currentYearBalanceSheet)
      }

      if (drePreviousYear !== null) {
        setDownloadDocDrePreviousYear(drePreviousYear)
      }

      if (dreCurrentYear !== null) {
        setDownloadDocDreCurrentYear(dreCurrentYear)
      }

      if (bankIndebtedness !== null) {
        setDownloadDocBankIndebtedness(bankIndebtedness)
      }

      if (procuration !== null) {
        setDownloadDocProcuration(procuration)
      }

      if (others !== null) {
        setDownloadDocOthers(others)
      }
    })
  }

  const handleContinueOrder = useCallback(async () => {
    
    setDisabledOrder(true)

    try {
      await api
        .get(`/document-company-upload/${userIdCompany}`)
        .then((res: any) => {
          const {
            ccmei,
            simplesStatementLastYear,
            lastYearSocialIncomeTax,
            lastContractChange,
            irpjLastYear,
            lastYearRevenue,
            balanceSheetPreviousYear,
            currentYearBalanceSheet,
            drePreviousYear,
            dreCurrentYear,
            procuration,
            bankIndebtedness
          } = res.data

          if (ccmei === null) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar o Contrato Social ou CCMEI.',
              type: 'error'
            })
          }
          else if(procuration == null && showProcuration){
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar a procuração.',
              type: 'error'
            })
          } else if (simplesStatementLastYear === null && companyType === 1) {
            setDownloadDocSimplesStatementLastYear(simplesStatementLastYear)
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar a Declaração do SIMPLES Último Ano.',
              type: 'error'
            })
          } else if (lastYearSocialIncomeTax === null && companyType === 1) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar o IRPF do Socio Último Ano.',
              type: 'error'
            })
          } else if (lastContractChange === null && companyType !== 1) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar a Última Alteração Contratual.',
              type: 'error'
            })
          }
           else if (
            irpjLastYear === null &&
            (companyType === 2 || companyType === 3)
          ) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar o IRPJ Último Ano.',
              type: 'error'
            })
          } else if (
            lastYearRevenue === null &&
            (companyType === 2 || companyType === 3)
          ) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar a Relação de Faturamento Último Ano.',
              type: 'error'
            })
          } else if (
            balanceSheetPreviousYear === null &&
            (companyType === 4 ||
              companyType === 0 ||
              companyType === null ||
              companyType === undefined)
          ) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar o Balanço ano anterior.',
              type: 'error'
            })
          } else if (
            currentYearBalanceSheet === null &&
            (companyType === 4 ||
              companyType === 0 ||
              companyType === null ||
              companyType === undefined)
          ) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar o Balanço ano vigente.',
              type: 'error'
            })
          } else if (
            drePreviousYear === null &&
            (companyType === 4 ||
              companyType === 0 ||
              companyType === null ||
              companyType === undefined)
          ) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar o DRE ano anterior.',
              type: 'error'
            })
          } else if (
            dreCurrentYear === null &&
            (companyType === 4 ||
              companyType === 0 ||
              companyType === null ||
              companyType === undefined)
          ) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar o DRE ano vigente.',
              type: 'error'
            })
          } else if (bankIndebtedness === null && companyType === 4) {
            setDisabledOrder(false)

            return addToast({
              title: 'Documento pendente',
              description:
                'Para concluir o pedido é necessário enviar a Relação de Endividamento Bancário.',
              type: 'error'
            })
          } else {
            handleSendOrder()
          }
        })
    } catch (err) {
      setDisabledOrder(true)

      return addToast({
        title: 'Documento pendente',
        description:
          'Para concluir o pedido é necessário enviar todos os documentos.',
        type: 'error'
      })
    }
  }, [addToast, companyType, history, location, userIdCompany])

  const handleSendOrder = async () => {
    if (isCompany) {
      return dispatch(setTabs({ id: '2' }))
    }
    
    let orderItems:any = (
      localStorage.getItem('@FleetSolutions:orderItems')
    )
    let resultArray;
    
    if (orderItems) {
      
      orderItems = JSON.parse(orderItems);
    
      resultArray = location.map((item, index) => {
        let matchingItemIndex = orderItems.findIndex((newItem: { productId: number }) => newItem.productId === item.productId);
        if (matchingItemIndex !== -1) {
          let matchingItem = orderItems[index];
          return {
            ...item,
            deliveryOption: matchingItem.deliveryOption || null,
            cityForPickup: matchingItem.deliveryOption === 1 ? null  : (matchingItem.cityforPickup ? matchingItem.cityforPickup : (Array.isArray(matchingItem.city) && matchingItem.city.length > 0 ? matchingItem.city[0]  : null)),
            uf: matchingItem.deliveryOption === 1 ? null : matchingItem.uf || null,
            address: matchingItem.address || null,
            finalPlate: matchingItem?.licensePlate?.join(' ') || null,
            dealershipId: matchingItem?.dealer || null,
          };
        } else {
          return item;
        }

       
      });


    }


    await api
      .post('/dealership-management-write', {
        products: orderItems ? resultArray : location,
        userId: userIdCompany,
        dealershipId: Number(
          localStorage.getItem('@FleetSolutions:dealershipId')
        ),
        dealershipGroupId: Number(
          localStorage.getItem('@FleetSolutions:dealershipGroupId')
        ),
        note: localStorage.getItem('@FleetSolutions:note'),
        vendedorMaster: vendedorMaster
      })
      .then((res: any) => {
        addToast({
          title: 'Pedido criado com sucesso!',
          type: 'success',
          description: 'Seu pedido foi criado com sucesso.'
        })

        localStorage.removeItem('@FleetSolutions:cart')
        localStorage.removeItem('@FleetSolutions:location')
        localStorage.removeItem('@FleetSolutions:userIdCompany')
        localStorage.removeItem('@FleetSolutions:note')
        localStorage.removeItem("@FleetSolutions:orderItems");

        setDisabledOrder(false)

        history.push('/my-orders')
      })
      .catch(({ response }: any) => {
        if (response.status !== 422) {
          addToast({
            title: 'Ocorreu um erro!',
            type: 'error',
            description: response.data
          })
          return
        }
        const outOfStockProductsData = response.data?.OutOfStockProducts
        const getProductsWithStock = (
          products: Array<IProductsOrder>,
          outOfStockProducts: Array<{
            ProductId: number
            QuantityStock: number
          }>
        ) => {
          return products.reduce(
            (withStock: Array<IProductsOrder>, product: IProductsOrder) => {
              const isOutOfStock = (outOfStock: {
                ProductId: number
                QuantityStock: number
              }) => {
                return product.productId === outOfStock.ProductId
              }
              const firstOutOfStok = outOfStockProducts.find(isOutOfStock)
              if (firstOutOfStok === undefined) return [...withStock, product]
              const currentItemAdded = withStock.filter(
                (item) => item.productId === product.productId
              )
              const hasQuantityInStock =
                currentItemAdded.length < firstOutOfStok.QuantityStock
              return hasQuantityInStock ? [...withStock, product] : withStock
            },
            []
          )
        }
        const locationWithStock = getProductsWithStock(
          location,
          outOfStockProductsData
        )
        setOutOfStockProducts(outOfStockProductsData)
        setLocation(locationWithStock)
        setStockModal(true)
      })
  }

  const handleBackPage = () => {
    if (orderId) {
      return history.goBack()
    }
    dispatch(setTabs({ id: '3' }))
  }

  const handleContinueOrderModal = () => {
    setStockModal(false)
    handleContinueOrder()
  }

  const handleCancelOrderModal = () => {
    history.push('/my-orders')
  }

  const hasVehicleOutOfStock = (vehicles: Array<{ QuantityStock: number }>) => {
    const firstOutOfStok = vehicles.find(
      ({ QuantityStock }) => QuantityStock === 0
    )
    return firstOutOfStok !== undefined
  }

  return (
    <Container>
      <h1 className="title">Carregar documentos</h1>
      <p className="description">
        Por favor, inclua os documentos abaixo do cliente para seguirmos a
        validação do cadastro.
      </p>
      <Content>
        <UploadDoc
          numberDoc={1}
          downloadDoc={downloadDocCcmei}
          docName="Contrato Social ou CCMEI"
          description="(Obrigatório)"
          fileType="Arquivos PDF até 5 mb"
          typeDocName="contrato-social-ou-ccmei"
        />

        {companyType === 1 && (
          <>
            <UploadDoc
              numberDoc={2}
              downloadDoc={downloadDocSimplesStatementLastYear}
              docName="Declaração do SIMPLES Último Ano"
              description="(Obrigatório)"
              fileType="Arquivos PDF até 5 mb"
              typeDocName="declaracao-simples-ultimo-ano"
            />
            <UploadDoc
              numberDoc={3}
              downloadDoc={downloadDocLastYearSocialIncomeTax}
              docName="IRPF do Socio Último Ano"
              description="(Obrigatório)"
              fileType="Arquivos PDF até 5 mb"
              typeDocName="irpf-socio-ultimo-ano"
            />
          </>
        )}

        {companyType === 0 ||
        companyType === null ||
        companyType === 2 ||
        companyType === 3 ||
        companyType === undefined ||
        companyType === 4 ? (
          <UploadDoc
            numberDoc={4}
            downloadDoc={downloadDocLastContractChange}
            docName="Última Alteração Contratual"
            description="(Obrigatório)"
            fileType="Arquivos PDF até 5 mb"
            typeDocName="ultima-alteracao-contratual"
          />
        ) : (
          ''
        )}

        {companyType === 2 || companyType === 3 ? (
          <UploadDoc
            numberDoc={5}
            downloadDoc={downloadDocIrpjLastYear}
            docName="IRPJ Último Ano"
            description="(Obrigatório)"
            fileType="Arquivos PDF até 5 mb"
            typeDocName="irpj-ultimo-ano"
          />
        ) : (
          ''
        )}

        {companyType === 2 || companyType === 3 ? (
          <UploadDoc
            numberDoc={6}
            downloadDoc={downloadDocLastYearRevenue}
            docName="Relação de Faturamento Último Ano"
            description="(Obrigatório)"
            fileType="Arquivos PDF até 5 mb"
            typeDocName="relacao-faturamento-ultimo-ano"
          />
        ) : (
          ''
        )}

        {companyType === 4 ||
        companyType === 0 ||
        companyType === null ||
        companyType === undefined ? (
          <UploadDoc
            numberDoc={7}
            downloadDoc={downloadDocBalanceSheetPreviousYear}
            docName="Balanço ano anterior"
            description="(Obrigatório)"
            fileType="Arquivos PDF até 5 mb"
            typeDocName="balanco-ano-anterior"
          />
        ) : (
          ''
        )}

        {companyType === 4 ||
        companyType === 0 ||
        companyType === null ||
        companyType === undefined ? (
          <UploadDoc
            numberDoc={8}
            downloadDoc={downloadDocCurrentYearBalanceSheet}
            docName="Balanço ano vigente"
            description="(Obrigatório)"
            fileType="Arquivos PDF até 5 mb"
            typeDocName="balanco-ano-vigente"
          />
        ) : (
          ''
        )}

        {companyType === 4 ||
        companyType === 0 ||
        companyType === null ||
        companyType === undefined ? (
          <UploadDoc
            numberDoc={9}
            downloadDoc={downloadDocDrePreviousYear}
            docName="DRE ano anterior"
            description="(Obrigatório)"
            fileType="Arquivos PDF até 5 mb"
            typeDocName="dre-ano-anterior"
          />
        ) : (
          ''
        )}




        {companyType === 4 ||
        companyType === 0 ||
        companyType === null ||
        companyType === undefined ? (
          <UploadDoc
            numberDoc={10}
            downloadDoc={downloadDocDreCurrentYear}
            docName="DRE ano vigente"
            description="(Obrigatório)"
            fileType="Arquivos PDF até 5 mb"
            typeDocName="dre-ano-vigente"
          />
        ) : (
          ''
        )}

        {companyType === 4 && (
          <UploadDoc
            numberDoc={11}
            downloadDoc={downloadDocBankIndebtedness}
            docName="Relação de Endividamento Bancário"
            description="(Obrigatório)"
            fileType="Arquivos PDF até 5 mb"
            typeDocName="relacao-endividamento-bancario"
          />
        )}

        {showProcuration && (
          <UploadDoc
            numberDoc={13}
            downloadDoc={downloadDocProcuration}
            docName="Procuração"
            description="(Obrigatório)"
            fileType="Arquivos PDF até 5 mb"
            typeDocName="procuracao"
          />
        )}

        <UploadDoc
          numberDoc={12}
          downloadDoc={downloadDocOthers}
          docName="Outros"
          description=""
          fileType="Arquivos PDF até 5 mb"
          typeDocName="outros"
        />
      </Content>
      <div className="wrapper-btn">
        <Button type="button" className="btn-back" onClick={handleBackPage}>
          Voltar
        </Button>
        <Button
          disabled={disabledOrder || company == null}
          className="btn-continue"
          onClick={handleContinueOrder}
        >
          CONTINUAR
        </Button>
      </div>

      <Modal
        width="600px"
        onCancel={() => setStockModal(false)}
        visible={stockModal}
        footer={null}
        data-testid="outOfStockModal"
      >
        <ContentModal>
          <h3>Veículos solicitados</h3>
          <p>
            Quantidade de veículos solicitados não disponível, caso clique em
            continuar a quantidade enviada será corrigida. Caso clique em
            cancelar, seu pedido será cancelado e voltará para tela de Meus
            Pedidos.
          </p>

          <Table>
            <thead>
              <tr>
                <th>Modelo</th>
                <th>Cor</th>
                <th>Solicitado</th>
                <th>Disponível</th>
              </tr>
            </thead>
            <tbody>
              {outOfStockProducts.map(
                ({
                  Model,
                  Color,
                  QuantityRequest,
                  QuantityStock,
                  ProductId
                }) => (
                  <tr key={ProductId}>
                    <td>{Model}</td>
                    <td>{Color}</td>
                    <td>{QuantityRequest}</td>
                    <td>{QuantityStock}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
          <div>
            <Button onClick={handleCancelOrderModal}>Cancelar</Button>
            <Button
              onClick={handleContinueOrderModal}
              disabled={hasVehicleOutOfStock(outOfStockProducts)}
              data-testid="continue-button-modal"
            >
              Continuar
            </Button>
          </div>
        </ContentModal>
      </Modal>
    </Container>
  )
}

export default UploadFile
